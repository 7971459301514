.carousel-references {
  #ref-laurent-perrier {
    .spe:nth-of-type(2) {
      &:after {
        left: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
  }
  #ref-venoge-oeuf {
    .spe:nth-of-type(1) {
      &:after {
        right: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -9em;
      }
      &.triggered:after {
        width: 8em;
      }
    }
  }
  #ref-lombard-tanagra {
    .spe:nth-of-type(1) {
      &:after {
        right: -11em;
      }
      &.triggered:after {
        width: 10em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -12em;
      }
      &.triggered:after {
        width: 11em;
      }
    }
  }
  #ref-caron {
    .spe:nth-of-type(1) {
      &:after {
        right: -4em;
      }
      &.triggered:after {
        width: 3em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -4em;
      }
      &.triggered:after {
        width: 3em;
      }
    }
  }
  #ref-roederer {
    .spe:nth-of-type(1) {
      &:after {
        right: -2em;
      }
      &.triggered:after {
        width: 1em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -8em;
      }
      &.triggered:after {
        width: 7em;
      }
    }
  }
  #ref-frederic-malle {
    .spe:nth-of-type(1) {
      &:after {
        right: -16em;
      }
      &.triggered:after {
        width: 15em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -15em;
      }
      &.triggered:after {
        width: 14em;
      }
    }
  }
  #ref-meamea {
    .spe:nth-of-type(1) {
      &:after {
        right: -7em;
      }
      &.triggered:after {
        width: 6em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -5em;
      }
      &.triggered:after {
        width: 4em;
      }
    }
  }
  #ref-venoge3b {
    .spe:nth-of-type(1) {
      &:after {
        right: -7em;
      }
      &.triggered:after {
        width: 6em;
      }
    }
  }
  #ref-saint-james {
    .spe:nth-of-type(1) {
      &:after {
        right: -7em;
      }
      &.triggered:after {
        width: 6em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -7em;
      }
      &.triggered:after {
        width: 6em;
      }
    }
  }
  #ref-3rivieres {
    .spe:nth-of-type(1) {
      &:after {
        right: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
  }
  #ref-tesseron {
    .spe:nth-of-type(1) {
      &:after {
        right: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
  }
  #ref-kusmi {
    .spe:nth-of-type(1) {
      &:after {
        right: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
    .spe:nth-of-type(2) {
      &:after {
        left: -10em;
      }
      &.triggered:after {
        width: 9em;
      }
    }
  }
}

@media (max-width: 1450px) {
  .carousel-references {
    .ref {
      .spe:nth-of-type(1) {
        &:after {
          right: -10vw;
        }
        &.triggered:after {
          width: 9vw
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -11vw;
        }
        &.triggered:after {
          width: 10vw
        }
      }
    }
    #ref-laurent-perrier {
      .spe:nth-of-type(1) {
        &:after {
          right: -10vw;
        }
        &.triggered:after {
          width: 9vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -8vw;
        }
        &.triggered:after {
          width: 7vw;
        }
      }
    }
    #ref-gosset {
      .spe:nth-of-type(1) {
        &:after {
          right: -10vw;
        }
        &.triggered:after {
          width: 9vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -11vw;
        }
        &.triggered:after {
          width: 10vw;
        }
      }
    }
    #ref-venoge-oeuf {
      .spe:nth-of-type(1) {
        &:after {
          right: -9vw;
        }
        &.triggered:after {
          width: 8vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -8vw;
        }
        &.triggered:after {
          width: 7vw;
        }
      }
    }
    #ref-lombard-tanagra {
      .spe:nth-of-type(1) {
        &:after {
          right: -10vw;
        }
        &.triggered:after {
          width: 9vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -11vw;
        }
        &.triggered:after {
          width: 10vw;
        }
      }
    }
    #ref-caron {
      .spe:nth-of-type(1) {
        &:after {
          right: -3vw;
        }
        &.triggered:after {
          width: 2vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -3vw;
        }
        &.triggered:after {
          width: 2vw;
        }
      }
    }
    #ref-roederer {
      grid-template-columns: 1fr 60% 1fr;
      .spe:nth-of-type(1) {
        &:after {
          right: -7vw;
        }
        &.triggered:after {
          width: 6vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -7vw;
        }
        &.triggered:after {
          width: 6vw;
        }
      }
    }
    #ref-frederic-malle {
      .spe:nth-of-type(1) {
        &:after {
          right: -15vw;
        }
        &.triggered:after {
          width: 14vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -14vw;
        }
        &.triggered:after {
          width: 13vw;
        }
      }
    }
    #ref-meamea {
      .spe:nth-of-type(1) {
        &:after {
          right: -6vw;
        }
        &.triggered:after {
          width: 5vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -4vw;
        }
        &.triggered:after {
          width: 3vw;
        }
      }
    }
    #ref-venoge3b {
      .spe:nth-of-type(1) {
        &:after {
          right: -6vw;
        }
        &.triggered:after {
          width: 5vw;
        }
      }
    }
    #ref-saint-james {
      .spe:nth-of-type(1) {
        &:after {
          right: -6vw;
        }
        &.triggered:after {
          width: 5vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -6vw;
        }
        &.triggered:after {
          width: 5vw;
        }
      }
    }
    #ref-3rivieres {
      .spe:nth-of-type(1) {
        &:after {
          right: -9vw;
        }
        &.triggered:after {
          width: 8vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -9vw;
        }
        &.triggered:after {
          width: 8vw;
        }
      }
    }
    #ref-tesseron {
      .spe:nth-of-type(1) {
        &:after {
          right: -9vw;
        }
        &.triggered:after {
          width: 8vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -9vw;
        }
        &.triggered:after {
          width: 8vw;
        }
      }
    }
    #ref-kusmi {
      .spe:nth-of-type(1) {
        &:after {
          right: -9vw;
        }
        &.triggered:after {
          width: 8vw;
        }
      }
      .spe:nth-of-type(2) {
        &:after {
          left: -9vw;
        }
        &.triggered:after {
          width: 8vw;
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .carousel-references {
    .ref {
      .spe {
        &:after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .carousel-references {
    .ref {
      height: calc(86vh - 112px);
      .spe {
        display: none;
      }
      img {
        max-height: calc(100% - 4rem);
        width: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .carousel-references {
    .ref {
      position: relative;
      display: flex;
      img {
        max-height: calc(100% - 4rem);
        width: auto;
      }
      p.client.classic-link {
        position:absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 6rem;
      }
    }
  }
}
.actu_single{

  article{
    position: relative;
  }

  .accroche{
    margin-bottom: 3em;
  }

  .slick-arrow {
    position: absolute;
    top: 0;
    right: -5em;
    width: 2em;
    height: .8em;

    * {
      transition: .3s ease-in-out;
    }

    &:hover {
      * {
        stroke: #000000;
      }
    }
  }

  img{
    padding-bottom: 1em;
  }

  .prevarrow {
    left: -5em;
  }
  .slick-dots {
    display: flex;
    position: initial;
    justify-content: center;
    li {
      margin-right: .5em;
      width: .5em;
      height: .5em;
      background: #C6C7C8;
      transition: .3s ease-in-out;
      border-radius: 1em;
      cursor: pointer;

      &.slick-active {
        background: #000000;
      }
    }

    button {
      color: transparent;
      background: transparent;
      border: none;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }


  }

  nav.arrows{
    display: flex;

    svg {
      height: .8em;
      width: 2em;

      * {
        transition: .3s ease-out;
      }
    }

    svg:first-child {
      margin-right: 2em;
    }

    svg:hover * {
      stroke: #000000;
    }
  }

  .classic-link {
    letter-spacing: initial;
    font-weight: bold;
  }
}

@media screen and(max-width: 476px) {
  .actu_single nav.arrows svg{
    margin: 2em;
  }
}
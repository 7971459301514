$classicGray: #313131;

@import 'loader';

@font-face {
  font-family: Gotham;
  src: url("../css/fonts/Gotham Light Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: Gotham;
  src: url("../css/fonts/Gotham-Medium_6.otf");
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url("../css/fonts/Gotham-Thin_2.otf");
  font-weight: 200;
}

@font-face {
  font-family: Gotham;
  src: url("../css/fonts/Gotham-Bold_4.otf");
  font-weight: bold;
}

@font-face {
  font-family: Gotham-Book;
  src: url("../css/fonts/Gotham-Book_3.otf");
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-size: 16px;
  font-family: 'Gotham', sans-serif;
  color: $classicGray;
  padding-top: 6em;
  padding-bottom: 2em;
  overflow-x: hidden;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: $classicGray;
}

::selection {
  color: white;
  background: $classicGray;
}

a {
  user-select: none;
}

img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 1em;
}

h1, h2, h3, p {
  line-height: 1.5;
}

//////////////////////////Navigation///////////////////////////////////////

#toggle_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: fixed;
  top: 2em;
  right: 1em;
  z-index: 3;
  transition: .6s ease-in-out;
  height: 1.2em;
  margin: 1px; //fix pour l'épaisseur instable des lignes du burger
}

.menu_line {
  display: inline-block;
  width: 1.5em;
  height: 3px;
  background: #222222;
}

#light {
  display: none;
  height: 1px;
  width: 1px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 20em;
  border-radius: 40em;
  background: rgba(255, 255, 255, 0.1);
  //display: none;
  box-shadow: 0 0 120px 125px rgba(255, 255, 255, 0.2);
}

#menu_box {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.menu {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  z-index: -1;
  background: #FFFFFF;
  opacity: 0;
  transform: translateX(100%);
  transition: all .8s ease-in-out, z-index 1s .5s;
  overflow-y: scroll;

  .container {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-gap: 2em;
  }

  #formulaire_contact {
    font-family: 'Gotham-Book', sans-serif;
    background: transparent;
    position: relative;
    z-index: 1;
    height: 200px;
    box-sizing: border-box;
    transform: translateX(-100%);
    opacity: 0;
    transition: height 1s 1s ease-in-out, opacity 1s ease-in-out, transform 1s ease-in-out;
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;

    > a {
      text-transform: uppercase;
      letter-spacing: 6px;
      display: flex;
      font-size: .9em;
      color: #000;
      text-decoration: none;
      margin-bottom: 3em;
      max-height: 1em;

      svg {
        width: 2em;
        margin-right: .5em;
        height: 1em;
      }
    }

    > p {
      text-transform: uppercase;
      letter-spacing: 6px;
      margin-bottom: 2em;
    }

    .mentions {
      font-size: .8em;
      margin-bottom: 2em;
    }

    .form-element {
      display: flex;
      flex-direction: column;
    }

    label {
      color: #8C8C8C;
      margin-bottom: .5em;
      display: none;
    }

    input:not([type=submit]), textarea {
      border: none;
      border-bottom: 1px solid;
      max-width: 25em;
      margin-bottom: 1em;
      font-family: 'Gotham-Book', sans-serif;
      font-size: 1rem;
      color: $classicGray;
      border-radius: 0;
      line-height: 3.5;
    }

    input[type=submit] {
      cursor: pointer;
    }

    textarea {
      max-width: 40em;
      font-family: 'Gotham-Book', sans-serif;
      font-size: 1rem;
    }

  }

  #formulaire_contact.open {
    opacity: 1;
    transform: translateX(0);
    height: 755px;
    transition: height .3s ease-in-out, opacity 1s ease-in-out, transform 1s ease-in-out;
    ~ nav {
      opacity: 0;
    }
  }

  nav {
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 6px;
    transition: 1s ease-out;
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: center;

    li {
      margin-bottom: 1em;
      opacity: 0;
      transform: translateX(0em);

    }

    a.active {
      position: relative;
      font-weight: bold;
      color: #b18e2d;
      width: 20em;

      &:after {
        opacity: 1;
      }
    }

    li:last-child {
      margin-bottom: 0;
    }

    li:nth-of-type(1) {
      transition: 1s .5s ease-in-out;
    }

    li:nth-of-type(2) {
      transition: 1s .7s ease-in-out;
    }

    li:nth-of-type(3) {
      transition: 1s .9s ease-in-out;
    }

    li:nth-of-type(4) {
      transition: 1s 1.1s ease-in-out;
    }

    li:nth-of-type(5) {
      transition: 1s 1.3s ease-in-out;
    }

    a {
      text-decoration: none;
      color: $classicGray;
      transition: .3s ease-in-out;

      &:after {
        //
        //display: flex;
        //position: absolute;
        //left: 19rem;
        //top: -12px;
        //content: '→';
        //font-size: 1.5em;
        //text-align: right;
        //opacity: 0;
        //transition: .3s ease-in-out;
        //
        display: flex;
        position: absolute;
        left: 19rem;
        top: 0;
        content: " ";
        font-size: 1.5em;
        text-align: right;
        opacity: 0;
        transition: .3s ease-in-out;
        background: url("./img/arr-sm-next-gold.svg") no-repeat center;
        width: 1em;
        height: 100%;
      }
    }

    a:hover {
      color: #b18e2d;
      &:after {
        opacity: 1;
      }
    }

  }

  .infos {
    line-height: 1.5;
    grid-column: 2/3;
    justify-self: center;
    align-self: center;
    width: 14.1em; //Identique à la largeur du menu pour un alignement identique
    transition: .5s ease-in-out;

    a {
      color: $classicGray;
      text-decoration: none;
    }

    p, .bold {
      margin-bottom: 1em;
    }

    .bold {
      font-weight: bold;
    }

    .insta_link {
      display: flex;
      align-items: center;
    }

    .insta_link img {
      height: 1.5em;
      width: 1.5em;
    }
  }
}

#menu_box:checked ~ #toggle_menu {
  transform: rotate(90deg);
}

#menu_box:checked ~ .menu {
  opacity: 1;
  transform: translateX(0);
  z-index: 2;
  transition: all 1s ease-in-out, z-index 0s;

  li {
    opacity: 1;
    transform: translateX(0);
  }
}

#topWindow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .container {
    //max-width: 1980px;
    max-width: unset;
    display: flex;
    justify-content: space-between;
    height: 5em;
    padding: 2em 1em 0;
    transition: .5s ease-in-out;
    background: rgba(255, 255, 255, 0.9);
  }

  .hodor {
    width: 2ch;
  }

  a {
    color: $classicGray;
    text-decoration: none;
  }

  img {
    object-fit: initial;
    width: 8em;
    height: auto;
    transition: .5s ease-in-out;
  }

  &.movible {
    position: fixed;
    z-index: 1;

    .container {
      height: 2em;
      padding: 1em;
    }

    img {
      width: 6em;
    }

    ~ #toggle_menu {
      top: 1em;
    }
  }

}

.classic-link {
  font-family: 'Gotham-Book', sans-serif;
  text-transform: uppercase;
  color: $classicGray;
  letter-spacing: 3px;
  font-size: 14px;
}

.classic-link:hover {
  color: #000000;
}

.caption {
  max-width: 37em;
  color: #7f7c80;
}

.classic_button {
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #606060;
  border: 1px solid #606060;
  padding: .8em 2em;
  transition: all .3s ease-in-out;
  background: transparent;
  display: inline-block;

  &:hover {
    border: 1px solid #000000;
    font-weight: normal;
    color: #000000;
  }
}

.slick-arrow {
  cursor: pointer;
}

h2 {
  text-transform: uppercase;
}

.trigger {
  transition: .5s ease-in-out;
  opacity: 0;
  transform: translateY(.5em);
}

.triggered {
  transition: .5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
}

.triggered.delayed_1 {
  transition: .5s .3s ease-in-out;
}

.triggered.delayed_2 {
  transition: .5s .5s ease-in-out;
}

.triggered.delayed_3 {
  transition: .5s .7s ease-in-out;
}

@media screen and (max-width: 878px) {
  .menu nav a {
    &:after {
      content: '';
    }
  }

  #light {
    display: none;
  }
}

@media screen and(max-width: 476px) {
  .menu {
    .container {
      grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    }

    nav {
      align-self: auto;
      margin-top: 30%;
      font-size: 1.5em;
    }

    #formulaire_contact {
      margin-bottom: 2em;
    }

    #formulaire_contact.open {
      ~ .infos {
        justify-self: start;
      }
    }
  }

  .trigger {
    opacity: 1;
    transform: translateY(0);
  }

  .clients {
    .classic_button {
      display: block;
      margin: auto;
      text-align: center;
    }
  }

  .page-about #prototypage {
    align-items: flex-start;

    .first .content {
      align-items: flex-start;
      text-align: left;
    }
  }
}



@import "pages/home";
@import "refslider";
@import "pages/about";
@import "pages/admin";
@import "pages/lists";
@import "pages/single-actu";
@import "pages/reference";
@import "pages/legal";
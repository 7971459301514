/////////////////////////Loader////////////////////////////////////////////

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: opacity 1s ease-in-out, z-index .1s 1s;

  #box {
    max-width: 10em;
    overflow: visible;
    opacity: 1;
    transition: .5s ease-in-out;
    position: inherit;
  *{
    stroke-width: 3px;
  }

    #couvercle {
      transform: translateY(-5em);
      stroke-dasharray: 3913;
      stroke-dashoffset: 3913;
      animation: draw 2s ease-in-out forwards, origin 1s 2s ease-in-out forwards, coloringTop 1s 2s ease-in-out forwards;
    }

    #socle {
      transform: translateY(3em);
      stroke-dasharray: 3116;
      stroke-dashoffset: 3116;
      animation: draw 2s ease-in-out forwards, origin 1s 2s ease-in-out forwards, coloringBottom 1s 2s ease-in-out forwards;
    }

    #lien {
      stroke-dasharray: 271;
      stroke-dashoffset: 271;
      animation: draw 1s 2.5s ease-in-out forwards, coloringTop 1s 2s ease-in-out forwards;
    }
  }
}

@keyframes draw {
  to{
    stroke-dashoffset: 0;
  }
}

@keyframes origin{
  to{
    transform: translateY(0);
  }
}

@keyframes coloringTop {
  to{
    stroke: #fff;
    fill: #424242;
  }
}

@keyframes coloringBottom {
  to{
    stroke: #fff;
    fill: #28272D;
  }
}

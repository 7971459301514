.admin{
  textarea {
    width: 100%; }

  .button, button, input[type="submit"], input[type="reset"], input[type="button"] {
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    background-color: #2c8898;
    color: #f9f9f9;
    border-radius: 1px;
    border: 1px solid #2c8898;
    cursor: pointer;
    box-sizing: border-box; }
  .button[disabled], button[disabled], input[type="submit"][disabled], input[type="reset"][disabled], input[type="button"][disabled] {
    cursor: default;
    opacity: .5; }
  .button:focus, .button:hover, button:focus, button:hover, input[type="submit"]:focus, input[type="submit"]:hover, input[type="reset"]:focus, input[type="reset"]:hover, input[type="button"]:focus, input[type="button"]:hover {
    background-color: #982c61;
    border-color: #982c61;
    color: #f9f9f9;
    outline: 0; }

  textarea, select, input[type] {
    color: #4a4a4a;
    padding: 6px 10px;
    /* The 6px vertically centers text on FF, ignored by Webkit */
    margin-bottom: 10px;
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box; }
  textarea:focus, select:focus, input[type]:focus {
    border: 1px solid #2c8898;
    outline: 0; }

  input[type="checkbox"]:focus {
    outline: 1px dotted #2c8898; }

  label, legend, fieldset {
    display: block;
    margin-bottom: .5rem;
    font-weight: 600; }
}

#make_article_content{
  font-size: 1rem;
  font-family: 'Gotham-Book', sans-serif;
}

#admin_list ul{
  margin-top: 2em;
  line-height: 2;
}
.page_legal {
  h2 {
    padding-bottom: 1em;
  }
  h3 {
    padding-bottom: 1em;
  }
  p+h3 {
    padding-top: 1em;
  }
}

.references_list {
  padding-top: 0;
  padding-bottom: 0;

  .container {
    max-width: 1600px;
  }

  .references {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.ref_single {

  .container {
    //max-width: 1200px;
  }

  .top-refs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .arrows {
    display: flex;

    svg {
      height: .8em;
      width: 2em;

      * {
        transition: .3s ease-out;
      }
    }

    svg:last-child {
      //margin-left: 2em;
    }

    svg:hover * {
      stroke: #000000;
    }
  }

  .nav_ref {
    position: relative;
    transition: 1.5s ease-in-out;

    .to_ref {
      position: absolute;
      height: 100%;
      width: 60em;
      top: 0;
      transition: .5s ease-out;
    }

    .to_ref:hover {
      opacity: .7;
    }

    .to_ref:first-child {
      left: calc(-62em - 6%);
    }

    .to_ref:last-child {
      right: calc(-62em - 6%);
    }
  }

  .illustration {
    padding-top: 1em;
    margin-bottom: 0;
    //max-height: 40em;
    max-height: 675px;
    min-height: 50vh;
  }

  .side {
    height: 100%;
  }

  h1 {
    text-transform: uppercase;
    letter-spacing: 6px;
    line-height: 1.5;
    font-size: .8em;

    strong {
      font-weight: 600;
    }
  }

  p {
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: .8em;
    font-weight: 600;
    text-transform: none;
    margin-bottom: 2rem;
  }

  h2 {
    text-transform: uppercase;
    letter-spacing: 6px;
    line-height: 1.5;
    font-size: 1.4em;
    margin-bottom: 1em;
  }

  .specs {
    font-size: .9em;
  }

  .answer {
    font-family: 'Gotham-Book', sans-serif;
  }

  .double_image {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-gap: .5em;
    margin-bottom: 4em;

    img {
      min-height: 35em;
    }
  }

  .ref_content {
    margin-bottom: 2em;

    .container {

      &:not(.first) {
        padding-top: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
      }
      &.first {
        padding-bottom: 0;
      }
    }
  }

  .carousel-article-single {
    padding-bottom: 2em;
    margin-bottom: 10em;

    img {
      max-height: 80vh;
    }

    .slick-arrow {
      position: absolute;
      bottom: 0;
      right: 1em;

      width: 2em;
      height: .8em;

      * {
        transition: .3s ease-in-out;
      }

      &:hover {
        * {
          stroke: #000000;
        }
      }
    }

    .prevarrow {
      right: 5em;
    }

    .slick-dots {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 1em;

      li {
        margin-right: .5em;
        width: .5em;
        height: .5em;
        background: #C6C7C8;
        transition: .3s ease-in-out;
        border-radius: 1em;
        cursor: pointer;

        &.slick-active {
          background: #000000;
        }
      }

      button {
        color: transparent;
        background: transparent;
        border: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

    }
  }
}
@media screen and(max-width: 1200px) {
  .ref_single {
    .illustration {
      min-height: unset;
    }
    .double_image {
      img {
        min-height: unset;
      }
    }
  }
}

@media screen and(max-width: 476px) {
  .ref_single {

    .illustration {
      margin-bottom: 1em;
    }

    h1 {
      display: none;
    }

    .arrows {
      width: 100%;
      justify-content: space-between;
      svg:last-child {
        margin: 0;
      }
    }
  }

}

.page-about {

  .container {
    max-width: 1200px;
  }

  h1 {
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: 6px;
    margin-bottom: 1em;
  }


  .hero {
    background: url("../img/background_about.jpg") no-repeat center;
    background-size: cover;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 6em - 8em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    margin-top: 1em;
    color: #fff;

    p {
      font-size: 1em;
      max-width: 40rem;
    }
  }

  .arrow {
    display: flex;
    justify-content: center;
    margin-top: 3em;

    img {
      width: 1em;
      height: 2em;
      object-fit: initial;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: .9em;
    letter-spacing: 6px;
    margin-bottom: 2.5em;
  }

  h3 {
    font-size: 2.5em;
    margin-bottom: 1em;
  }

  p {
    max-width: 60em;
    margin-bottom: 2em;
  }

  section {
    margin-bottom: 10em;
  }


  .presentation {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-gap: 4em;

    p {
      max-width: 25em;
    }

    img{
      height: 100%;
      max-height: 40em;
    }

  }

  .presentation.first {
    text-align: right;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  #list {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h2 {
      width: 100%;
    }
  }

  .engagement {
    margin-bottom: 4em;
    align-self: start;
  }

  .engagements_list {
    margin: auto;

    p {
      max-width: 50em;
      padding-left: 10%;
      color: #7f7c80;
    }
  }

  .clients {
    margin-top: 5em;

    ul {
      max-width: 60em;
      margin: 10em auto 4em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        max-width: 10em;
        margin: 1em;
      }
    }

  }

  .bottom_image {
    max-height: 90vh;
  }
}

@media screen and (max-width: 40em) {
  .page-about .presentation img{
    height: initial;
  }
}

@media screen and (max-width: 476px) {
  .page-about section{
    margin-bottom: 2em;
  }
}




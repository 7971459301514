.hero {
  box-sizing: border-box;
  //cursor: none;
  margin-bottom: 2em;
  .slick-slider {
    max-height: calc(100vh - 18em);
    margin-bottom: 3em;
  }

  .slick-dots {
    //display: flex;
    display: none !important;
    position: absolute;
    top: -.8em;
    left: 0;
    width: 100%;

    li {
      width: 100%;
      background: transparent;
      transition: 1s ease-in-out;
    }

    .slick-active {
      background: black;
    }

    button {
      background: transparent;
      border: none;
      color: transparent;
    }
  }

  img {
    width: auto;
    max-width: 100%;
    //height: calc(100vh - 6em - 8em - 3em); //Taille d'écran - hauteur du header - taille de la flèche du bas - marge sous l'image
    height: 100%;
    max-height: calc(100vh - 18em);
    margin: auto;
  }

}

.arrow {
  display: flex;
  justify-content: center;
  margin-top: 3em;

  img {
    width: 1em;
    height: 2em;
    object-fit: initial;
    transition: .3s ease-out;
    margin-bottom: 0;
  }
}

.arrow:hover img {
  opacity: .5;
  transform: translateY(1em);
}

#topIntro {
  height: 3em;
}

#hpmenu {
  ul {
    margin: auto;
    text-align: center;
    padding-top: 1em;
    li {
      display: inline-block;
      a {
        color: #000;
        opacity: 0.6;
        font-size: 15px;
        letter-spacing: 6px;
        font-family: Gotham, sans-serif;
        text-transform: uppercase;
        display: block;
        font-weight: 500;
        padding: 1em 2em;
        text-decoration: none;
        transition: opacity 0.3s;
        &.active, &:hover {
          opacity: 1;
        }
      }
    }
  }
  & + .arrow {
    margin-top: 0;
  }
}

@media (max-width: 878px) {
  #hpmenu {
    display: none;
  }
}

#introduction {
  text-align: center;
  line-height: 1.5;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  padding: 4em 0;
  margin-top: 4em;

  .cache {
    position: absolute;
    top: 0;
    width: 100%;
    height: 80%;
    z-index: 1;
    transition: 2s ease-in-out;

    &.trigger {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .cache-1 {
    left: 50%;
    background: url("../img/hp-droite.jpg") no-repeat left;
  }

  .cache-2 {
    right: 50%;
    background: url("../img/hp-gauche.jpg") no-repeat right;
  }

  &.triggered {
    .cache-1 {
      transform: translateX(600px);
    }

    .cache-2 {
      transform: translateX(-600px);
    }
  }

  .container {
    width: 100%;
  }

  h1 {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-weight: bold;
  }

  .arrow {
    margin-top: 5em;
  }
}

.intro_content_box {
  max-width: 55em;
  width: 100%;
  margin: 0 auto 3em;
}

.intro_box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  transition: 1s 1s ease-in-out;
  letter-spacing: 1px;
  margin-bottom: 2em;
}

.box_marks {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  right: -5%;
  cursor: pointer;
}

.box_mark {
  height: 8px;
  width: 8px;
  background: #9b989c;
  border-radius: 2em;
  transition: 1s ease-in-out;

  &.active {
    background: #000000;
  }
}

.intro_box.active, .box_mark.active {
  opacity: 1;
  transition-delay: .8s;
}

.references {
  font-family: 'Gotham-Book', sans-serif;
  min-height: 95vh;
  box-sizing: border-box;
  padding: 4em 0;

  .container {
    max-width: 1920px;
  }

  h2 {
    text-align: center;
    margin-bottom: 3em;
    letter-spacing: 6px;
  }

  .classic_button {
    text-align: center;
    margin: 2em auto 0;
    display: block;
    max-width: max-content;
  }
}

.carousel-references {
  .spe {
    line-height: 1.5;
    max-width: 17em;
    position: relative;
    opacity: 1;
    color: transparent;
    transform: translateY(0);

    p:first-child {
      color: transparent;
      transition: 1s 1.5s ease-in-out;
    }

    p {
      font-size: 14px;
      margin-bottom: 1em;
      transition: 1s 2.5s ease-in-out;
      transform: translateY(1em);
    }
  }

  .spe.triggered {
    color: $classicGray;

    p:first-child {
      color: #6A686B;
    }

    p {
      transform: translateY(0);
    }
  }

  img {
    grid-column: 2/3;
    grid-row: 1/4;
    justify-self: center;
    align-self: center;
    //max-width: 35em;
    max-height: 72vh;
    width: auto;
    opacity: 0;
    transform: translateX(-2em);
    transition: 1.5s ease-out;
    object-fit: initial;
  }

  img.triggered {
    opacity: 1;
    transform: translateX(0);
  }

  a.classic-link {
    grid-column: 1/4;
    justify-self: center;
    align-self: end;
    font-size: .8em;
    text-decoration: underline;
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .ref {
    display: grid;
    grid-template-columns: 1fr 40% 1fr;
    grid-gap: 2em;
    min-height: calc(86vh - 112px);

    .spe:nth-of-type(1) {
      grid-column: 1/2;
      grid-row: 3/4;
      justify-self: right;
      text-align: right;
      overflow: visible;

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        background: #9e9b9f;
        right: -11em;
        top: .7em;
        transition: 1s .5s ease-in-out;
      }
    }

    .client {
      grid-column: 3/4;
      grid-row: 1/2;
      justify-self: start;
      span {
        transition: .5s letter-spacing;
      }
      a {
        display: block;
        margin-top: 2em;
        margin-bottom: -4em;
        font-size: 12px;
        color: #000;
        text-decoration: underline;
        font-family: Gotham, sans-serif;
      }
    }

    p.client.classic-link {
      position: relative;
      z-index: 1;
      span {
        opacity: 0;
      }
    }

    .spe:nth-of-type(2) {
      grid-column: 3/4;
      grid-row: 2/3;
      justify-self: start;

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        background: #9e9b9f;
        left: -12em;
        top: .7em;
        transition: 1s .5s ease-in-out;
      }
    }

    .spe:nth-of-type(1).triggered {
      &:after {
        width: 10em;
      }
    }

    .spe:nth-of-type(2).triggered {
      &:after {
        width: 11em;
      }
    }

  }

  .prevarrow, .nextarrow {
    height: 3em;
    //width: 1.5em;
    width: 2.2rem;
    position: absolute;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .prevarrow {
    left: 0;
    top: calc(50% - 3em);
    //background: url('../img/prevarrow.svg') no-repeat center;
    background: url('../img/arr-sm-prev.svg') no-repeat center;
    background-size: contain;
    z-index: 1;
  }

  .nextarrow {
    right: 0;
    top: calc(50% - 3em);
    //background: url('../img/nextarrow.svg') no-repeat center;
    background: url('../img/arr-sm-next.svg') no-repeat center;
    background-size: contain;
  }
  &:hover {
    .prevarrow, .nextarrow {
      opacity: 1;
    }
  }

}
/*
body.references_list {
  .carousel-references {
    .ref {
      .client {
        a {
          text-decoration: none;
          padding: 0.8em 1.2em;
          border: 1px solid #333;
          background: #000;
          color: #FFF;
          &:hover {
            background: #FFF;
            color: #000;
          }
        }
      }
    }
  }
}
*/

#pass {
  font-size: .8em;
  margin-top: 1em;
  display: block;
}

.actu {
  //min-height: 100vh;
  margin-bottom: 5em;

  article {
    max-width: 55em;
    margin: auto;
  }

  img {
    height: 50vh;
  }

  .date {
    text-transform: uppercase;
    text-align: center;
    margin: 2em;
    letter-spacing: 3px;
  }

  h2 {
    font-size: 1.5em;
    text-align: center;
    margin: 0 auto 1em;
    line-height: 1.5;
    max-width: 20em;
    font-weight: 500;
  }

  .accroche {
    line-height: 1.5;
    margin-bottom: 2em;
    //white-space : pre;
  }

  .links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    a {
      margin-bottom: 1em;
    }
  }
}

.insta {
  margin-top: 10em;

  h3 {
    text-decoration: underline;
    text-align: center;
    margin: 0 auto 4em;
    font-weight: bold;
  }

  .insta_link {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    img {
      height: 1.5em;
      width: 1.5em;
      margin-bottom: 1em;
    }

    p {
      color: $classicGray;
      font-size: .9em;
      text-transform: uppercase;
      letter-spacing: 6px;
      font-weight: bold;
    }
  }

}

#insta-feed .instagram_gallery {
  max-height: 30em;
  padding-bottom: 4em;

  .insta_pic, .instagram-image {
    margin: 0 1em;

    img {
      margin: auto;
      max-width: 25em;
    }
  }

  .slick-arrow {
    position: absolute;
    bottom: 0;
    right: 1em;

    width: 2em;
    height: .8em;

    * {
      transition: .3s ease-in-out;
    }

    &:hover {
      * {
        stroke: #000000;
      }
    }
  }

  .prevarrow {
    right: 3em;
  }

  .slick-dots {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 1em;

    li {
      margin-right: .5em;
      width: .5em;
      height: .5em;
      background: #C6C7C8;
      transition: .3s ease-in-out;
      border-radius: 1em;
      cursor: pointer;

      &.slick-active {
        background: #000000;
      }
    }

    button {
      color: transparent;
      background: transparent;
      border: none;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

}

@media screen and (min-width: 1921px), screen and (max-aspect-ratio: 4/3) {
  .hero {
    img {
      height: auto;
    }
  }
}

@media screen and (min-width: 1921px) {
  .container {
    max-width: 70vw;
  }
  .home {
    .hero_carousel {
      //height: calc(100vh - 18em);
    }
  }
}

@media screen and (max-width: 1024px) {
  .references h2 {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 878px) {

  .ref {
    display: block;
    .spe {
      display: none;
    }
  }

  .carousel-references {
    .ref {
      display: block;
      .client {
        margin-top: -3rem;
        text-align: center;
      }
      padding-bottom: 1rem;
    }
    img {
      //grid-column: 1/4;
      display: block;
      margin: 0 auto;
    }
  }

}

@media screen and (max-width: 476px) {

  .carousel-references .ref {
    height: 100%;
    min-height: unset;
  }

  .intro_box {
    font-size: 1em;
  }

  .carousel-references img {
    max-width: 20em;
  }

  #topIntro {
    height: 0;
  }

  #introduction .cache-1 {
    transform: translateX(600px);
  }

  #introduction .cache-2 {
    transform: translateX(-600px);
  }

  .actu {
    .accroche {
      text-align: center;
    }

    .links {
      align-items: center;
      flex-direction: column;
    }
  }

  .menu {
    .infos {
      grid-column: 1/2;
    }
  }

}

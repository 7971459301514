.actu_list{

  article{
    .content {
      margin: auto;
      max-width: 22.5em;
    }

    margin-bottom: 10em;
    text-align: center;
  }

  article:nth-of-type(1){
    max-width: 55em;
    text-align: left;
  }

  .links{
    justify-content: center;
  }
}

.references_list{
  .references{
    height: auto;
    margin: 2em 0;
  }
}